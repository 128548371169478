import React, {useEffect} from 'react';
import '../styles/App.css'
import { Route, Switch } from 'react-router-dom';

import HomePage from '../pages/HomePage';
import ProductPage from '../pages/ProductPage';
import ProductListPage from '../pages/ProductListPage';
import ErrorPage from '../pages/ErrorPage';
// import Navigation from './Navigation'

const Page = (props) => {

  // window.scrollTo(0, 0)
  // useEffect(() => {
  //   console.log("to top")
  //   window.scrollTo(0, 0)
  // }, [])

  return (
    <>
      {/* <Navigation/> */}
      <Switch>
        <Route path="/" exact  component={(routes) => <HomePage routes={routes} data={props.data}/>}/>
        <Route path="/lista-przepisow"  component={(routes) => <ProductListPage routes={routes} dane={props.data}/>}/>
        <Route path="/przepis/:id"  component={(routes) => <ProductPage routes={routes} dane={props.data}/>}/>
        <Route component={ErrorPage} />
      </Switch>
    </>
  );
}

export default Page;

// <Route path="/lista-przepisow"  component={(routes) => <ProductListPage routes={routes} data={props.data}/>}/>
// <Route path="/przepis/:id"  component={(routes) => <ProductPage routes={routes} dane={props.data}/>}/>