import React from 'react';
import '../styles/App.css'
import SearchRecipeComponent from './SearchRecipeComponent';

const SearchRecipe = (props) => {

  return (
    <div className="search-product">
      <div className='content'>
        <SearchRecipeComponent data={props.data} numItem={4}/>
      </div>
    </div>

  );
}

export default SearchRecipe;