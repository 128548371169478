import React from 'react';
import { useEffect, useState, useRef } from "react";
import '../styles/App.css'
import { Link } from 'react-router-dom';

import przepisy from '../test.js'
import Poziom_trudnosci from '../functions/PoziomTrudnosci.js';

import usePagination from './Pagination';
import { Button, Pagination } from '@mui/material';
import { Checkbox } from '@mui/material';
import { FormControlLabel  } from '@mui/material';
import { FormControl } from '@mui/material';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import parse from 'html-react-parser';
import { Rating } from 'react-simple-star-rating'
import { ReactComponent as FullStar } from "../images/fullStar.svg"
import { ReactComponent as EmptyStar } from "../images/emptyStar.svg"
import { ReactComponent as EmptyStarList } from "../images/empty-star-list.svg"
import { ReactComponent as SelectIcon } from "../images/select-icon.svg"
import { ArrowBackIos } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/styles';

import cebula from "../images/cebula.png"
import lisc from "../images/lisc.png"
import liscie from "../images/liscie.png"
import ogorek from "../images/ogorek.png"
import time_icon from "../images/time.svg"

let newTodos= []
let newTodos2= []

const useStyles = makeStyles({
  ul: {
    "& .MuiPaginationItem-root": {
      color: "green",
      borderRadius: 100,
      border: '0.13em dashed rgb(42 91 40 / 40%)',
      width: 45,
      height: 45,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
        width: 20,
        height: 20,
        minWidth: 'unset',
        fontSize: '12px',
        padding: '12px',
      }
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: '#2a5b28',
      backgroundColor: '#B5DB78',
      border: '0.13em dashed rgb(42 91 40 / 100%)',
      
    },
    "& .MuiPaginationItem-root.Mui-selected:hover": {
      backgroundColor: '#D1F39A',
    },
    "& .MuiPaginationItem-previousNext": {
      color: '#2a5b28',
      backgroundColor: '#B5DB78',
      border: '0',
      
    },
    "& .MuiPaginationItem-previousNext:hover": {
      backgroundColor: '#D1F39A',
    },
    "& .MuiPaginationItem-previousNext.Mui-disabled": {
      color: '#2a5b28',
      backgroundColor: 'transparent',
      border: '0.13em solid rgb(42 91 40 / 30%)',
      opacity: 1
    },
  },
  formControl: {
    minWidth: 120
  },
  dropdownStyle: {
    border: 0,
    marginTop: 0,
    borderRadius: "5%",
    '& .MuiList-root': {
      background:'#B5DB78',
      fontFamily: 'mochamattariw03-regularRg',
    },
    '& .MuiList-root li': {
      fontFamily: 'mochamattariw03-regularRg',
      fontSize: 18,
    },
    '& .MuiList-root li.Mui-selected': {
      background:'#1c5b28',
      color: "#FFF",
    },
    '& .MuiList-root li svg': {
      maxWidth:20,
    },

  },
  button: {
    fontFamily: 'mochamattariw03-regularRg',
    fontSize: '30px',
    color: '#f47921',
    letterSpacing: '.01px',
    padding: '0px 11px',
    maxHeight: '43px',
    borderRadius: '10px',
    position: 'relative',
    right: '-10px',
    background: 'transparent',
    border: 0
  }
});

var filteredList = [];

const SearchRecipe = (props) => {
  const classes = useStyles();

  const [newData, setNewData] = useState()
  const dataGet = () => {
    fetch('https://www.naturalneporanki.pl/v2/bcknd/data.php')
      .then((response) => response.json())
      .then(data => {
        setNewData(data)
        console.log("SRC ponowne pobranie danych...")
      });
  }

  useEffect(() => {
    if(localStorage.getItem('mustReloadData') === "true"){
      dataGet()
    }
  }, []);


  let przepisy2 = []


  if(newData && newData.recipes.length > 0){
    przepisy2 = newData.recipes
  }else{
    przepisy2 = props.data 
  }

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState({});
  const [filterlist2, setFilterlist] = useState(() => przepisy2 || []);
  const [filterlist2_temp, setFilterlist_temp] = useState(() => przepisy2 || []);

  const baseRecipNum = filterlist2.length

  const [checkboxes, setCheckboxes] = useState({})



  const [level, setLevel] = useState('')
  const [rating, setRating] = useState('')
  const [time, setTime] = useState('')

  const [toFilter, setToFilter] = useState([]);

  const [open, setOpen] = useState(false);
  let [page, setPage] = useState(1);

  const PER_PAGE = props.numItem;
  const count = Math.ceil(filterlist2.length / PER_PAGE);
  const _DATA = usePagination(filterlist2, PER_PAGE);

  const [checked, setChecked] = React.useState(false);

  const handleChangeCheckbox = (e, item) => {
    setChecked(e.target.checked);
    if(e.target.checked === true){
      newTodos = toFilter.slice();
      newTodos.push(item);
      console.log('newTodos',newTodos)
      setToFilter(newTodos);
      setCheckboxes({
        ...checkboxes,
        [item]: e.target.checked
      })
    }else{
      newTodos2 = toFilter.filter((t) => t !== item);
      setToFilter(newTodos2);
      setCheckboxes({[item]: e.target.checked})
    }

  };

  // const handleAdd = (e, item, set) => {
  //   set(e.target.checked)
  //   const newTodos = toFilter.slice();
  //   newTodos.push(item);
  //   setToFilter(newTodos);
  //   setCheckboxes({
  //     ...checkboxes,
  //     [item]: e.target.checked
  //   })
  // };

  // const handleRemove= (e, item, set) => {
  //   set(e.target.checked)
  //   const newTodos = toFilter.filter((t) => t !== item);
  //   setToFilter(newTodos);
  //   setCheckboxes({[item]: e.target.checked})
  // };

  const handleChangeSelectLevel = (e) => {
    console.log(e.target.value)
    setLevel(e.target.value);
  };

  const handleChangeSelectRating = (e) => {
    setRating(e.target.value);
  };

  const handleChangeSelectTime = (e) => {
    setTime(e.target.value);
  };

  const handleCleaner = (e) => {
    setFilterlist(props.data);
    document.getElementById("searchFilter").value = "";
    setLevel('')
    setRating('')
    setTime('')
    newTodos.length = 0
    newTodos2.length = 0
    // cleanCheckboxes()

    // setJajka(false)
    setCheckboxes({})
    // setWinogrona(false)
    // setSer(false)
    // setOrzechy(false)
    // setTwarog(false)
    // setChleb(false)
    // setJablko(false)
    // setGruszka(false)

  };


  // if(checkboxes.length === 0){
  //   if(jajka === true){
  //     console.log("kurwa DZIAŁAJ!!!!")
  //     setJajka(false)
  //   }
  // }


  const filterAllOptions = (data, call) => {
      
      // var dataBase = filteredList || przepisy2 || filterlist2
      // <MenuItem value={'do 15 min'}>do 15 min</MenuItem>
      // <MenuItem value={'do 30 min'}>do 30 min</MenuItem>
      // <MenuItem value={'do 1 godziny'}>do 1 godziny</MenuItem>

      if(level !== '' && call === 'level'){
        if(level !== ''){
          console.log("level..............")
          var levelFIltered = filterlist2_temp.filter((item)  => {
            return item.level === level; 
          });
          setFilterlist(levelFIltered);
        }
        if(level !== '' && rating !== ''){
          console.log("level ponowne..............")
          var levelFIltered2 = filterlist2_temp.filter((item)  => {
            return item.level === level; 
          });
          if(rating === 0){
            var ratingFIltered2 = levelFIltered2.filter((item)  => {
              return item.raiting > 0 && item.raiting <= 1; 
            });
          }else if(rating === 1){
            var ratingFIltered2 = levelFIltered2.filter((item)  => {
              return item.raiting > 1 && item.raiting <= 2; 
            });
          }else if(rating === 2){
            var ratingFIltered2 = levelFIltered2.filter((item)  => {
              return item.raiting > 2 && item.raiting <= 3; 
            });
          }else if(rating === 3){
            var ratingFIltered2 = levelFIltered2.filter((item)  => {
              return item.raiting > 3 && item.raiting <= 4; 
            });
          }else if(rating === 4){
            var ratingFIltered2 = levelFIltered2.filter((item)  => {
              return item.raiting > 4 && item.raiting <= 5; 
            });
          }else if(rating === 5){
            var ratingFIltered2 = levelFIltered2.filter((item)  => {
              return item.raiting === 5; 
            });
          }
          setFilterlist(ratingFIltered2);
        }
        if(level !== '' && time !== ''){
          console.log("level.time..............")

          if(time === "do 15 min"){
            var timeFIltered6 = filterlist2_temp.filter((item)  => {
              return item.time === '00:15' || item.time === '00:10'; 
            });
          }
          if(time === "około 30 min"){
            var timeFIltered6 = filterlist2_temp.filter((item)  => {
              return item.time === '00:20' || item.time === '00:25' || item.time === '00:30' || item.time === '00:35'; 
            });
          }
          if(time === "około 1 godziny"){
            var timeFIltered6 = filterlist2_temp.filter((item)  => {
              return item.time === '00:40' || item.time === '00:45' || item.time === '00:50' || item.time === '00:55'; 
            });
          }

          var levelFIltered6 = timeFIltered6.filter((item)  => {
            return item.level === level; 
          });
          setFilterlist(levelFIltered6);
        }
        if(level !== '' && time !== '' && rating !== ''){
          console.log("level.time.rating.............")

          if(time === "do 15 min"){
            var timeFIltered7 = filterlist2_temp.filter((item)  => {
              return item.time === '00:15' || item.time === '00:10'; 
            });
          }
          if(time === "około 30 min"){
            var timeFIltered7 = filterlist2_temp.filter((item)  => {
              return item.time === '00:20' || item.time === '00:25' || item.time === '00:30' || item.time === '00:35'; 
            });
          }
          if(time === "około 1 godziny"){
            var timeFIltered7 = filterlist2_temp.filter((item)  => {
              return item.time === '00:40' || item.time === '00:45' || item.time === '00:50' || item.time === '00:55'; 
            });
          }

          if(rating === 0){
            var ratingFIltered7 = timeFIltered7.filter((item)  => {
              return item.raiting > 0 && item.raiting <= 1; 
            });
          }else if(rating === 1){
            var ratingFIltered7 = timeFIltered7.filter((item)  => {
              return item.raiting > 1 && item.raiting <= 2; 
            });
          }else if(rating === 2){
            var ratingFIltered7 = timeFIltered7.filter((item)  => {
              return item.raiting > 2 && item.raiting <= 3; 
            });
          }else if(rating === 3){
            var ratingFIltered7 = timeFIltered7.filter((item)  => {
              return item.raiting > 3 && item.raiting <= 4; 
            });
          }else if(rating === 4){
            var ratingFIltered7 = timeFIltered7.filter((item)  => {
              return item.raiting > 4 && item.raiting <= 5; 
            });
          }else if(rating === 5){
            var ratingFIltered7 = timeFIltered7.filter((item)  => {
              return item.raiting === 5; 
            });
          }

          var levelFIltered7 = ratingFIltered7.filter((item)  => {
            return item.level === level; 
          });

          setFilterlist(levelFIltered7);
        }
      }

      if(rating !== '' && call === 'rating'){
        if(rating !== ''){
          console.log("rating..............")
          if(rating === 0){
            var ratingFIltered = filterlist2_temp.filter((item)  => {
              return item.raiting > 0 && item.raiting <= 1; 
            });
          }else if(rating === 1){
            var ratingFIltered = filterlist2_temp.filter((item)  => {
              return item.raiting > 1 && item.raiting <= 2; 
            });
          }else if(rating === 2){
            var ratingFIltered = filterlist2_temp.filter((item)  => {
              return item.raiting > 2 && item.raiting <= 3; 
            });
          }else if(rating === 3){
            var ratingFIltered = filterlist2_temp.filter((item)  => {
              return item.raiting > 3 && item.raiting <= 4; 
            });
          }else if(rating === 4){
            var ratingFIltered = filterlist2_temp.filter((item)  => {
              return item.raiting > 4 && item.raiting <= 5; 
            });
          }else if(rating === 5){
            var ratingFIltered = filterlist2_temp.filter((item)  => {
              return item.raiting === 5; 
            });
          }
          setFilterlist(ratingFIltered);
        }
        if(rating !== '' && level !== ''){
          console.log("rating ponowne..............")
          if(rating === 0){
            var ratingFIltered3 = filterlist2_temp.filter((item)  => {
              return item.raiting > 0 && item.raiting <= 1; 
            });
          }else if(rating === 1){
            var ratingFIltered3 = filterlist2_temp.filter((item)  => {
              return item.raiting > 1 && item.raiting <= 2; 
            });
          }else if(rating === 2){
            var ratingFIltered3 = filterlist2_temp.filter((item)  => {
              return item.raiting > 2 && item.raiting <= 3; 
            });
          }else if(rating === 3){
            var ratingFIltered3 = filterlist2_temp.filter((item)  => {
              return item.raiting > 3 && item.raiting <= 4; 
            });
          }else if(rating === 4){
            var ratingFIltered3 = filterlist2_temp.filter((item)  => {
              return item.raiting > 4 && item.raiting <= 4; 
            });
          }else if(rating === 5){
            var ratingFIltered3 = filterlist2_temp.filter((item)  => {
              return item.raiting === 5; 
            });
          }
          var levelFIltered3 = ratingFIltered3.filter((item)  => {
            return item.level === level; 
          });
          setFilterlist(levelFIltered3);
        }
        if(rating !== '' && time !== ''){
          console.log("rating..............")

          if(time === "do 15 min"){
            var timeFIltered9 = filterlist2_temp.filter((item)  => {
              return item.time === '00:15' || item.time === '00:10'; 
            });
          }
          if(time === "około 30 min"){
            var timeFIltered9 = filterlist2_temp.filter((item)  => {
              return item.time === '00:20' || item.time === '00:25' || item.time === '00:30' || item.time === '00:35'; 
            });
          }
          if(time === "około 1 godziny"){
            var timeFIltered9 = filterlist2_temp.filter((item)  => {
              return item.time === '00:40' || item.time === '00:45' || item.time === '00:50' || item.time === '00:55'; 
            });
          }

          if(rating === 0){
            var ratingFIltered9 = timeFIltered9.filter((item)  => {
              return item.raiting > 0 && item.raiting <= 1; 
            });
          }else if(rating === 1){
            var ratingFIltered9 = timeFIltered9.filter((item)  => {
              return item.raiting > 1 && item.raiting <= 2; 
            });
          }else if(rating === 2){
            var ratingFIltered9 = timeFIltered9.filter((item)  => {
              return item.raiting > 2 && item.raiting <= 3; 
            });
          }else if(rating === 3){
            var ratingFIltered9 = timeFIltered9.filter((item)  => {
              return item.raiting > 3 && item.raiting <= 4; 
            });
          }else if(rating === 4){
            var ratingFIltered9 = timeFIltered9.filter((item)  => {
              return item.raiting > 4 && item.raiting <= 5; 
            });
          }else if(rating === 5){
            var ratingFIltered9 = timeFIltered9.filter((item)  => {
              return item.raiting === 5; 
            });
          }
          setFilterlist(ratingFIltered9);
        }
        if(rating !== '' && level !== '' && time !== ''){

          if(time === "do 15 min"){
            var timeFIltered8 = filterlist2_temp.filter((item)  => {
              return item.time === '00:15' || item.time === '00:10'; 
            });
          }
          if(time === "około 30 min"){
            var timeFIltered8 = filterlist2_temp.filter((item)  => {
              return item.time === '00:20' || item.time === '00:25' || item.time === '00:30' || item.time === '00:35'; 
            });
          }
          if(time === "około 1 godziny"){
            var timeFIltered8 = filterlist2_temp.filter((item)  => {
              return item.time === '00:40' || item.time === '00:45' || item.time === '00:50' || item.time === '00:55'; 
            });
          }

          var levelFIltered8 = timeFIltered8.filter((item)  => {
            return item.level === level; 
          });

          if(rating === 0){
            var ratingFIltered8 = levelFIltered8.filter((item)  => {
              return item.raiting > 0 && item.raiting <= 1; 
            });
          }else if(rating === 1){
            var ratingFIltered8 = levelFIltered8.filter((item)  => {
              return item.raiting > 1 && item.raiting <= 2; 
            });
          }else if(rating === 2){
            var ratingFIltered8 = levelFIltered8.filter((item)  => {
              return item.raiting > 2 && item.raiting <= 3; 
            });
          }else if(rating === 3){
            var ratingFIltered8 = levelFIltered8.filter((item)  => {
              return item.raiting > 3 && item.raiting <= 4; 
            });
          }else if(rating === 4){
            var ratingFIltered8 = levelFIltered8.filter((item)  => {
              return item.raiting > 4 && item.raiting <= 5; 
            });
          }else if(rating === 5){
            var ratingFIltered8 = levelFIltered8.filter((item)  => {
              return item.raiting === 5; 
            });
          }
          setFilterlist(ratingFIltered8);

        }
      }

      if(time !== '' && call === 'time'){
        if(time !== ''){
          if(time === "do 15 min"){
            var timeFIltered = filterlist2_temp.filter((item)  => {
              return item.time === '00:15' || item.time === '00:10'; 
            });
          }
          if(time === "około 30 min"){
            var timeFIltered = filterlist2_temp.filter((item)  => {
              return item.time === '00:20' || item.time === '00:25' || item.time === '00:30' || item.time === '00:35'; 
            });
          }
          if(time === "około 1 godziny"){
            var timeFIltered = filterlist2_temp.filter((item)  => {
              return item.time === '00:40' || item.time === '00:45' || item.time === '00:50' || item.time === '00:55'; 
            });
          }
          setFilterlist(timeFIltered);
        }
        if(time !== '' && level !== ''){
          console.log("time ponowne..............")
          if(time === "do 15 min"){
            var timeFIltered4 = filterlist2_temp.filter((item)  => {
              return item.time === '00:15' || item.time === '00:10'; 
            });
          }
          if(time === "około 30 min"){
            var timeFIltered4 = filterlist2_temp.filter((item)  => {
              return item.time === '00:20' || item.time === '00:25' || item.time === '00:30' || item.time === '00:35'; 
            });
          }
          if(time === "około 1 godziny"){
            var timeFIltered4 = filterlist2_temp.filter((item)  => {
              return item.time === '00:40' || item.time === '00:45' || item.time === '00:50' || item.time === '00:55'; 
            });
          }
          var levelFIltered4 = timeFIltered4.filter((item)  => {
            return item.level === level; 
          });
          setFilterlist(levelFIltered4);
        }
        if(time !== '' && rating !== ''){
          if(rating === 0){
            var ratingFIltered10 = filterlist2.filter((item)  => {
              return item.raiting > 0 && item.raiting <= 1; 
            });
          }else if(rating === 1){
            var ratingFIltered10 = filterlist2.filter((item)  => {
              return item.raiting > 1 && item.raiting <= 2; 
            });
          }else if(rating === 2){
            var ratingFIltered10 = filterlist2.filter((item)  => {
              return item.raiting > 2 && item.raiting <= 3; 
            });
          }else if(rating === 3){
            var ratingFIltered10 = filterlist2.filter((item)  => {
              return item.raiting > 3 && item.raiting <= 4; 
            });
          }else if(rating === 4){
            var ratingFIltered10 = filterlist2.filter((item)  => {
              return item.raiting > 4 && item.raiting <= 5; 
            });
          }else if(rating === 5){
            var ratingFIltered10 = filterlist2.filter((item)  => {
              return item.raiting === 5; 
            });
          }

          if(time === "do 15 min"){
            var timeFIltered10 = ratingFIltered10.filter((item)  => {
              return item.time === '00:15' || item.time === '00:10'; 
            });
          }
          if(time === "około 30 min"){
            var timeFIltered10 = ratingFIltered10.filter((item)  => {
              return item.time === '00:20' || item.time === '00:25' || item.time === '00:30' || item.time === '00:35'; 
            });
          }
          if(time === "około 1 godziny"){
            var timeFIltered10 = ratingFIltered10.filter((item)  => {
              return item.time === '00:40' || item.time === '00:45' || item.time === '00:50' || item.time === '00:55'; 
            });
          }
          
          setFilterlist(timeFIltered);
        }
        if(time !== '' && rating !== '' && level !== ''){

          var levelFIltered11 = filterlist2.filter((item)  => {
            return item.level === level; 
          });

          if(rating === 0){
            var ratingFIltered11 = levelFIltered11.filter((item)  => {
              return item.raiting > 0 && item.raiting <= 1; 
            });
          }else if(rating === 1){
            var ratingFIltered11 = levelFIltered11.filter((item)  => {
              return item.raiting > 1 && item.raiting <= 2; 
            });
          }else if(rating === 2){
            var ratingFIltered11 = levelFIltered11.filter((item)  => {
              return item.raiting > 2 && item.raiting <= 3; 
            });
          }else if(rating === 3){
            var ratingFIltered11 = levelFIltered11.filter((item)  => {
              return item.raiting > 3 && item.raiting <= 4; 
            });
          }else if(rating === 4){
            var ratingFIltered11 = levelFIltered11.filter((item)  => {
              return item.raiting > 4 && item.raiting <= 5; 
            });
          }else if(rating === 5){
            var ratingFIltered11 = levelFIltered11.filter((item)  => {
              return item.raiting === 5; 
            });
          }

          if(time === "do 15 min"){
            var timeFIltered11 = ratingFIltered11.filter((item)  => {
              return item.time === '00:15' || item.time === '00:10'; 
            });
          }
          if(time === "około 30 min"){
            var timeFIltered11 = ratingFIltered11.filter((item)  => {
              return item.time === '00:20' || item.time === '00:25' || item.time === '00:30' || item.time === '00:35'; 
            });
          }
          if(time === "około 1 godziny"){
            var timeFIltered11 = ratingFIltered11.filter((item)  => {
              return item.time === '00:40' || item.time === '00:45' || item.time === '00:50' || item.time === '00:55'; 
            });
          }

          setFilterlist(timeFIltered11);
        }

      }

  }

  const [tempCount, setTempCount] = useState(0)

  const handleChange = (e, p) => {
    setTempCount(p)
    setPage(p);
    _DATA.jump(p);

  };

  function onKeyUpHandler(e) {
    setSearch(e.target.value.toLowerCase());
  }

  function onSelectItem(e, item) {
    setSelected(item);
    setSearch("");
    clearFilter();
  }

  function clearFilter() {
    document.getElementById("searchFilter").value = "";
  }

  
  
  const filters = {
    tags: [],
  };
  
  filters.tags = toFilter


  let filteredArr2 = filterlist2_temp.filter((item) => {
    const tags = item.tagi;
    const usingSplit = tags.split(',');
    return toFilter.every(f => usingSplit.includes(f));
  });


  useEffect(() => {
    setFilterlist(newData && newData.recipes);
  }, [newData]);

  useEffect(() => {

    filterAllOptions(przepisy2, 'level')

  }, [level]);

  useEffect(() => {

    filterAllOptions(przepisy2, 'rating')

  }, [rating]);

  useEffect(() => {

    filterAllOptions(przepisy2, 'time')

  }, [time]);

  useEffect(() => {
    if(checkboxes.jajka === true){
      console.log('change!!!')
      setFilterlist(filteredArr2)
    }

  }, [checkboxes.jajka]);

  useEffect(() => {
    if(checkboxes.chleb === true){
      console.log('change!!!')
      setFilterlist(filteredArr2)
    }

  }, [checkboxes.chleb]);

  useEffect(() => {
    if(checkboxes.parówki === true){
      console.log('change!!!')
      setFilterlist(filteredArr2)
    }

  }, [checkboxes.parówki]);

  useEffect(() => {
    if(checkboxes.parowki100s === true){
      console.log('change!!! parowki100s')
      setFilterlist(filteredArr2)
    }

  }, [checkboxes.parowki100s]);

  useEffect(() => {
    if(checkboxes.parowki100k === true){
      console.log('change!!! parowki100k')
      setFilterlist(filteredArr2)
    }

  }, [checkboxes.parowki100k]);

  useEffect(() => {
    if(checkboxes.warzywa === true){
      console.log('change!!!')
      setFilterlist(filteredArr2)
    }

  }, [checkboxes.warzywa]);
  
  useEffect(() => {
    if(checkboxes.ser === true){
      console.log('change!!!')
      setFilterlist(filteredArr2)
    }

  }, [checkboxes.ser]);



  useEffect(() => {
    if(przepisy2){
      filteredList = przepisy2.filter((item)  => {
        let all_str = `${item.tagi}`.toLowerCase();
        return all_str.indexOf(search) > -1; 
      });
    }
    setFilterlist(filteredList);
  }, [search]);


  const levelLabel = (v) => {
    if(v === 0){
      return "Łatwe"
    }else if(v === 1){
      return "Średnie"
    }else if(v === 2){
      return "Trudne"
    }
  }
  const ratingLabel = (v) => {
    let output = ''
    switch(v){
      case 0:
        output = 'Ocena do 1';
        break;
      case 1:
        output = 'Ocena do 2';
        break;
      case 2:
        output = 'Ocena do 3';
        break;
      case 3:
        output = 'Ocena do 4';
        break;
      case 4:
        output = 'Ocena do 5';
        break;
      // case 5:
      //   output = 'Ocena 5';
      //   break;
      default:
        break;  
    }
    return output
  }


  useEffect(()=>{
    if(props.numItem > 4 && tempCount !== 0){
      setTimeout(()=>{
        window.scrollTo({
          top: 275,
          behavior: 'smooth',
        });
      }, 650)
    }
  },[handleChange])


  return (
    <div className='search-page'>
      <div id={'IDprzepis'} className='content'>
        <h2 className={'h2WithBTN'}>
          Znajdź przepis 
          {/* <Link to={`/`}><ArrowBackIos/></Link> */}
        </h2>
        <input
            id="searchFilter"
            type="text"
            className='searchInput'
            defaultValue={search}
            placeholder="Wyszukaj przepis..."
            onKeyUp={onKeyUpHandler}
          />
        <div className='filters'>
          <div className='filters-items'>
            {/* <DropDownMenu /> */}

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <Select
                displayEmpty={true}
                renderValue={checkboxes => Object.keys(checkboxes).length === 0 ? <span className='selected'>SKŁADNIKI</span> : 'SKŁADNIKI'}
                // renderValue={checkboxes => checkboxes !== '' ? <span className='selected'>{levelLabel(level)}</span> : 'SKŁADNIKI'}
                value={"SKŁADNIKI"}
                onChange={handleChangeSelectLevel}
                label="SKŁADNIKI"
                disableUnderline
                IconComponent = {SelectIcon}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                <div id='IDup' className='customSelectList'>
                  <ul className="dropdown-menu">
                    <li className="dropdown-menu__item">
                      <FormControlLabel label="Jajka" control={
                        <Checkbox 
                          onChange={(e)=>handleChangeCheckbox(e,"jajka")}
                          size="small" 
                          checked={checkboxes.jajka} 
                        /> 
                      }/> 
                    </li>
                    <li className="dropdown-menu__item">
                      <FormControlLabel label="Parówki" control={
                        <Checkbox 
                          onChange={(e)=>handleChangeCheckbox(e,"parówki")}
                          size="small" 
                          checked={checkboxes.parowki} 
                        /> 
                      }/> 
                    </li>
                    <li className="dropdown-menu__item">
                      <FormControlLabel label="Parówki 100% z szynki" control={
                        <Checkbox 
                          onChange={(e)=>handleChangeCheckbox(e,"parowki100s")}
                          size="small" 
                          checked={checkboxes.parowki100s} 
                        /> 
                      }/> 
                    </li>
                    <li className="dropdown-menu__item">
                      <FormControlLabel label="Parówki 100% z kurczaka" control={
                        <Checkbox 
                          onChange={(e)=>handleChangeCheckbox(e,"parowki100k")}
                          size="small" 
                          checked={checkboxes.parowki100k} 
                        /> 
                      }/> 
                    </li>
                    <li className="dropdown-menu__item">
                      <FormControlLabel label="Ser" control={
                        <Checkbox 
                          onChange={(e)=>handleChangeCheckbox(e,"ser")}
                          size="small" 
                          checked={checkboxes.ser} 
                        /> 
                      }/>
                    </li>
                    <li className="dropdown-menu__item">
                      <FormControlLabel label="Warzywa" control={
                        <Checkbox 
                          onChange={(e)=>handleChangeCheckbox(e,"warzywa")}
                          size="small" 
                          checked={checkboxes.warzywa} 
                        /> 
                      }/>
                    </li>
                    <li className="dropdown-menu__item">
                      <FormControlLabel label="chleb" control={
                        <Checkbox 
                          onChange={(e)=>handleChangeCheckbox(e,"chleb")}
                          size="small" 
                          checked={checkboxes.chleb} 
                        /> 
                      }/>
                    </li>
                  </ul>
                </div>
              </Select>
            </FormControl>


            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <Select
                displayEmpty={true}
                renderValue={level => level !== '' ? <span className='selected'>{levelLabel(level)}</span> : 'POZIOM TRUDNOŚCI'}
                value={level}
                onChange={handleChangeSelectLevel}
                label="Poziom Trudności"
                disableUnderline
                IconComponent = {SelectIcon}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                {/* <MenuItem>Poziom Trudności</MenuItem> */}
                <MenuItem value={0}>Łatwy</MenuItem>
                <MenuItem value={1}>Średni</MenuItem>
                <MenuItem value={2}>Trudny</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <Select
                classes={{ ul: classes.ul }}
                displayEmpty={true}
                renderValue={rating => rating !== '' ? <span className='selected'>{ratingLabel(rating)}</span> : 'OCENA'}
                value={rating}
                onChange={handleChangeSelectRating}
                label="Ocena"
                disableUnderline
                IconComponent = {SelectIcon}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >
                <MenuItem value={0}>
                  <FullStar size={28} />
                  <EmptyStarList size={28} />
                  <EmptyStarList size={28} />
                  <EmptyStarList size={28} />
                  <EmptyStarList size={28} />
                </MenuItem>
                <MenuItem value={1}>
                  <FullStar size={28} />
                  <FullStar size={28} />
                  <EmptyStarList size={28} />
                  <EmptyStarList size={28} />
                  <EmptyStarList size={28} />
                </MenuItem>
                <MenuItem value={2}>
                  <FullStar size={28} />
                  <FullStar size={28} />
                  <FullStar size={28} />
                  <EmptyStarList size={28} />
                  <EmptyStarList size={28} />
                </MenuItem>
                <MenuItem value={3}>
                  <FullStar size={28} />
                  <FullStar size={28} />
                  <FullStar size={28} />
                  <FullStar size={28} />
                  <EmptyStarList size={28} />
                </MenuItem>
                <MenuItem value={4}>
                  <FullStar size={28} />
                  <FullStar size={28} />
                  <FullStar size={28} />
                  <FullStar size={28} />
                  <FullStar size={28} />
                </MenuItem>
                {/* <MenuItem value={5}>Ocena 5</MenuItem> */}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <Select
                classes={{ select: classes.select }}
                displayEmpty={true}
                renderValue={time => time !== '' ? <span className='selected'>{time}</span> : 'CZAS PRZYGOTOWANIA'}
                value={time}
                onChange={handleChangeSelectTime}
                label="Czas Przygotowania"
                disableUnderline
                IconComponent = {SelectIcon}
                MenuProps={{ classes: { paper: classes.dropdownStyle } }}
              >

                <MenuItem value={'do 15 min'}>do 15 min</MenuItem>
                <MenuItem value={'około 30 min'}>około 30 min</MenuItem>
                <MenuItem value={'około 1 godziny'}>około 1 godziny</MenuItem>

                {/* <MenuItem value={'00:15'}>00:15</MenuItem>
                <MenuItem value={'00:20'}>00:20</MenuItem>
                <MenuItem value={'00:25'}>00:25</MenuItem>
                <MenuItem value={'00:30'}>00:30</MenuItem>
                <MenuItem value={'00:35'}>00:35</MenuItem>
                <MenuItem value={'00:40'}>00:40</MenuItem>
                <MenuItem value={'00:45'}>00:45</MenuItem>
                <MenuItem value={'00:50'}>00:50</MenuItem>
                <MenuItem value={'00:55'}>00:55</MenuItem>
                <MenuItem value={'more'}>01:00 lub więcej</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div>
            <button className={classes.button} onClick={handleCleaner}>
              Wyczyść filtry
            </button>
          </div>
        </div>  
          
        <div>
          <ul className="list-group">
            {_DATA.currentData().map((item, key) => (
              <li
                className="list-group-item"
                key={key}
                value={item.id}
                onClick={(e) => onSelectItem(e, item)}
              >
                    <Link to={`/przepis/${item.url}`}>
                      <img src={item.photo_mini_url} alt={item.title} />
                    </Link>
                    <div className='rating-count'>
                      <Rating ratingValue={(item.raiting/5)*100} 
                            size={34} 
                            readonly 
                            // fillColorArray={fillColorArray}
                            fullIcon={<FullStar size={34} />}
                            emptyIcon={<EmptyStar size={34} />}
                        />
                        {`
                            (${item.raiting_num} OCEN) 
                        `}

                    </div>
                    <Link to={`/przepis/${item.url}`}>
                      <h3><underline>{parse(item.title)}</underline></h3>
                    </Link>
                    <div className='aditional-parm'>
                      
                        <div className='r-time'>
                          <img src={time_icon} alt="timeicon"/>
                          <b>{`${item.time !== null ? item.time : "BRAK"} `}</b> {item.time !== null && "MIN"}
                        </div>

                        <div className='r-level'>
                          Poziom trudności: 
                          <b>{`${Poziom_trudnosci(item.level)}`}</b>
                        </div>
                        
                    </div>
                    <Link className='more-btn' to={`/przepis/${item.url}`}>więcej</Link>
              </li>
            ))}
          </ul>

          <Pagination
            count={count}
            classes={{ ul: classes.ul }}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            pageRangeDisplayed={1}
          />
        </div>
      
      </div>

      <img src={cebula} alt="apetizer" className={`cebula-lista ${props.numItem <= 4 ? "disabled" : "" }`}/> 
      <img src={lisc} alt="apetizer" className={`lisc-lista ${props.numItem <= 4 ? "disabled" : "" }`}/>    
      <img src={liscie} alt="apetizer" className={`liscie-lista ${props.numItem <= 4 ? "disabled" : "" }`}/>
      <img src={ogorek} alt="apetizer" className={`ogorek-lista ${props.numItem <= 4 ? "disabled" : "" }`}/>            
    </div>

  );
}

export default SearchRecipe;