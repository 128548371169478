import React from 'react';
import '../styles/App.css'
import SearchRecipeComponent from '../components/SearchRecipeComponent';
import product_img_3 from "../images/product_img_3.png"
import Product_img_4 from "../images/Product_img_4.png"
import bazylia_2 from "../images/bazylia_2.png"


const ProductListPage = (props) => {
  return (
    <div className="products products-list">

      <img src={product_img_3} alt="apetizer" className='ap1'/> 
      <img src={Product_img_4} alt="apetizer" className='ap2'/> 
      <img src={bazylia_2} alt="apetizer" className='ap3'/> 

      <div className='content'>
        <SearchRecipeComponent data={props.dane.recipes} numItem={12}/>
      </div>
    </div>

  );
}

export default ProductListPage;