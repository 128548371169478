import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import '../styles/App.css'
import przepisy from '../test.js'
import parse from 'html-react-parser';
import { Rating } from 'react-simple-star-rating'
// import FullStar from '../images/fullStar.svg'
// import CustomFullStar from '../images/full-star.svg'
import { ReactComponent as FullStar } from "../images/fullStar.svg"
import { ReactComponent as EmptyStar } from "../images/emptyStar.svg"

import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';

import czosnek from '../images/czosnek.png'
import czosnek1 from '../images/czosnek1.png'

const RankingRecipe = (props) => {

  const [dataAPI, setDataAPI] = useState(props.data || [])
  
  const dataGet = () => {
    fetch('https://www.naturalneporanki.pl/v2/bcknd/data.php')
      .then((response) => response.json())
      .then(data => {
        setDataAPI(data.recipes)
        console.log("RR ponowne pobranie danych...")
      });
  }

  useEffect(() => {
    if(localStorage.getItem('mustReloadData') === "true"){
      dataGet()
    }
  }, []);


  const sort = (d) => {
    d.sort(function(a, b) {
      var keyA = a.raiting,
        keyB = b.raiting;
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
    });
    return d
  }



  return (
    <div className="products-ranking">
        <div className='content'>
            <div className='ranking-mini-title'>
              • Ranking przepisów •
            </div>
            <h4>Wasze ulubione dania <Link to={`/lista-przepisow`}>Zobacz wszystkie przepisy</Link></h4>
            <div>
                <ul>
                {sort(dataAPI).slice(0, 4).map((x, index) => (
                    <li className='rating-item' key={x.id}>
                      <span className='rating-num'>
                        {index + 1}
                      </span>
                      <img src={x.photo_mini_url} alt={x.title} />
                      <div className='rating-count'>
                        <Rating ratingValue={(x.raiting/5)*100} 
                              size={34} 
                              readonly 
                              // fillColorArray={fillColorArray}
                              fullIcon={<FullStar size={34} />}
                              emptyIcon={<EmptyStar size={34} />}
                          />
                          {`
                              (${x.raiting_num} OCEN) 
                          `}
                      </div>
                      <Link to={`/przepis/${x.url}`}>
                          <h3><underline>{parse(x.title)}</underline></h3>
                      </Link>
                      <Link className='more-btn' to={`/przepis/${x.url}`}>więcej</Link>
                    </li>

                    ))}
                </ul>
            </div>
        </div>
        <ParallaxProvider>
            <Parallax speed={2}>
            <img src={czosnek} className='img-czosnek' alt='czosnek'/>
            </Parallax>
        </ParallaxProvider>
        <img src={czosnek1} className='img-czosnek-1' alt='czosnek'/>
        
    </div>

  );
}

export default RankingRecipe;