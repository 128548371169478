const Poziom_trudnosci = (d) => {
    if(d === 0){
      return "Łatwy"
    }else if(d === 1){
      return "Średni"
    }else if(d === 2){
      return "Trudny"
    }else if(d === null){
      return ""
    }else{
      return ""
    }
  }

export default Poziom_trudnosci;