import React from 'react';
import '../styles/App.css'
import { Link } from 'react-router-dom';
import Product from '../components/Product'


const ProductPage = (props) => {

  return (
    <>
      <Product dane={props.dane} date={props} />
    </>

  );
}

export default ProductPage;