import React, { Component } from 'react';
import '../styles/App.css'
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './Header';
import Page from './Page';
import Footer from './Footer';
import ScrollToTop from '../hooks/ScrollToTop';
import logo from '../images/rodrigosloader.gif';
import { Lines } from 'react-preloaders';


const Loading =()=>
  <div className="loading">
    <div className='loaderGif'>
      <img src={logo} alt="loading..." />
    </div>
  </div>  

class App extends Component {


  constructor(props) {
    super(props)
    this.state = { 
      loading: true,
      data: []
    }
  }
  componentDidMount() {

    fetch('https://www.naturalneporanki.pl/v2/bcknd/data.php')
    .then((response) => response.json())
    .then(data => {
        this.setState({ data: data });
        setTimeout(()=>{this.setState({loading: false})}, 1750)
    });
    
    // this.isLoading = setTimeout(()=>{this.setState({loading: false})}, 3000);

  }

  componentWillUnmount(){
    clearTimeout(this.isLoading);
  }
  
  timer = () => setTimeout(()=>{
    this.setState({loading: false})
  }, 3000);

  render() {
    const {loading} = this.state;

    if(localStorage.getItem('mustReloadData') === "true"){
      localStorage.removeItem('mustReloadData')
    }


    return (
      loading ? (<Loading/>)
      :(
        <Router basename={'/'}>
          <ScrollToTop>
            <div className="app">
              <header>
                {<Header data={this.state.data}/>}
              </header>
              <main className="main">
                <section className="page">
                  {<Page data={this.state.data}/>}
                </section>
              </main>
              <footer>{<Footer />}</footer>
            </div>
          </ScrollToTop>
        </Router>
      )
    );
  }
}

export default App;
