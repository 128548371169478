import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import Button from '@mui/material/Button';
import przepisy from '../test.js'
import Link from 'react-router-dom/Link';
import parse from 'html-react-parser';
import usePagination from './Pagination';
import { Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Link2 from 'react-scroll/modules/components/Link';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import play from "../images/player.png"

const useStyles = makeStyles({
    ul: {
      "& .MuiPaginationItem-root": {
        color: "green",
        borderRadius: 100,
        border: '0.13em dashed rgb(42 91 40 / 40%)',
        width: 45,
        height: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
            width: 20,
            height: 20,
            minWidth: 'unset',
            fontSize: '12px',
            padding: '12px',
        }
      },
      "& .MuiPaginationItem-root.Mui-selected": {
        color: '#2a5b28',
        backgroundColor: '#B5DB78',
        border: '0.13em dashed rgb(42 91 40 / 100%)',
        
      },
      "& .MuiPaginationItem-root.Mui-selected:hover": {
        backgroundColor: '#D1F39A',
      },
      "& .MuiPaginationItem-previousNext": {
        color: '#2a5b28',
        backgroundColor: '#B5DB78',
        border: '0',
        
      },
      "& .MuiPaginationItem-previousNext:hover": {
        backgroundColor: '#D1F39A',
      },
      "& .MuiPaginationItem-previousNext.Mui-disabled": {
        color: '#2a5b28',
        backgroundColor: '#fff',
        border: '0.13em solid rgb(42 91 40 / 30%)',
        opacity: 1
      },
    }
  });

const LastVideo = (props) => {


    const classes = useStyles();
    let [page, setPage] = useState(1);


    const [events, setEvents] = useState(props.data)

    const [przepisAPI, setPrzepisAPI] = useState(props.data)


    let active_events = events.events.filter((o)=>{
        return o.active !== false
    })



    // let katarMeksyk = active_events.find((o)=>{return o.id === 22})
    // let katarArabia = active_events.find((o)=>{return o.id === 24})
    // let katarArgentyna = active_events.find((o)=>{return o.id === 23})

    // let events_change = active_events.filter((o)=>{
    //     return o.id !== 22 && o.id !== 23 && o.id !== 24
    // })

    // katarMeksyk.id = 24
    // katarArabia.id = 23
    // katarArgentyna.id = 22

    // events_change.push(katarArgentyna)
    // events_change.push(katarArabia)
    // events_change.push(katarMeksyk)

    // console.log('events_change',events_change)
    // active_events = events_change




    // let lastVideo_ID = Math.max(...przepisy.events.map((p) => p.id), 0)
    const [lastVideo_ID, setLastVideo_ID] = useState(() => Math.max(...active_events.map((p) => p.id), 0));
 
    // var lastVideo_Data = events.events.filter((item)  => {
    //     return item.id === lastVideo_ID; 
    // });
    var lastVideo_Recipes = przepisAPI.recipes.filter((item)  => {
        return item.event_id === lastVideo_ID; 
    });
    var lastVideo_Event = active_events.filter((item)  => {
        return item.id === lastVideo_ID; 
    });

    const handleChange = (e, p) => {
        if(_DATA && p){
            setPage(p);
            _DATA.jump(p);
        }
    };


    const sort = (d) => {
        d.sort(function(a, b) {
          var keyA = a.id,
            keyB = b.id;
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
        });
        return d
    }


    const PER_PAGE = 3;
    const count = Math.ceil(events && active_events.length / PER_PAGE);
    const _DATA = usePagination(sort(events && active_events), PER_PAGE);



    useEffect(() => {
        if(props.video_id !== undefined){
            console.log('ustaw id z banera: ',props.video_id)
            setLastVideo_ID(props.video_id)
        }
    }, [props.video_id]);
        // console.log('props.video_id',props.video_id)
        // console.log('lastVideo_ID',lastVideo_ID)


    //   useEffect(() => {
    //     if(props.video_id !== null || props.video_id !== undefined){
    //         setLastVideo_ID(props.video_id)
    //     }
    //   }, []);

    return (
        <>
        <div id="video" className='last-movie'>
            <div className='content'>

                <ParallaxProvider>
                    <Parallax speed={1}>
                        <div className='main-last-movie'>
                            <div className='last-movie-title'>• Przepis na Naturalne Poranki •</div>
                            <div className='movie-title'>
                                <div className='section-title'>
                                    <b>{lastVideo_Event ? parse(lastVideo_Event[0].title) : ""}</b>
                                </div>
                                <div>
                                <span>Przepisy z odcinka</span>
                                </div>
                            </div>
                            <div className='last-movie-box'>
                                <div className='last-movie-box_movie'>
                                    <div className='movie-movie'>
                                        <ReactPlayer 
                                            className='react-player'
                                            pip={true}
                                            url={lastVideo_Event ? lastVideo_Event[0].video_url : ""}
                                            controls={true}
                                            loop={true}
                                            muted={false}
                                            playsinline={true}
                                            light={lastVideo_Event ? lastVideo_Event[0].photo_url : ""}
                                            playIcon={<img src={play} alt={'play-button'}/>}
                                            playing                             
                                        />
                                    </div>
                                </div>
                                <div className='last-movie_recipe'>
                                    <span className='mobile-fix-title'>Przepisy z odcinka</span>
                                    <div className='recipe-box'>
                                        {lastVideo_Recipes.map((item, key) => (
                                        <li
                                            className="list-group-item"
                                            key={key}
                                            value={item.id}
                                        >
                                                <Link to={`/przepis/${item.url}`}>
                                                    <div className='mini-recipe-photo' style={{backgroundImage: `url(${item.photo_mini_url})`}}></div>
                                                </Link>
                                                <Link to={`/przepis/${item.url}`}>
                                                    <h3><underline>{parse(item.title)}</underline></h3>
                                                </Link>
                                        </li>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Parallax>
                </ParallaxProvider>

                <ParallaxProvider>
                    <Parallax speed={1}>
                        <div className='other-last-movie'>
                            <div className='video-list'>
                                {/* <div className='last-movie-title'>
                                    • Przepisy Naturalne Poranki •
                                </div> */}
                                <div className='movie-title'>
                                    Zobacz poprzednie odcinki
                                </div>
                                <div className='video-list-list'>
                                    {_DATA.currentData().map((item, key) => (
                                        <li
                                            className={`list-group-item ${(item.id === lastVideo_ID) ? 'active' : ''}`}
                                            key={key}
                                        >
                                            <Link2 
                                                onClick={()=>{
                                                    setLastVideo_ID(item.id)
                                                }}
                                                className={'mini-recipe-photo-link'}
                                                to="video" 
                                                delay={500}
                                                spy={true} 
                                                smooth={true}
                                            >
                                                    <div className='mini-recipe-photo' style={{backgroundImage: `url(${item.photo_url})`}}></div>
                                            </Link2>
                                            <Link2 
                                                onClick={()=>{
                                                    setLastVideo_ID(item.id)
                                                }}
                                                className={'h3-link'}
                                                to="video" 
                                                delay={500}
                                                spy={true} 
                                                smooth={true}
                                            >
                                                <h3><underline>{parse(item.title)}</underline></h3>
                                            </Link2>
                                            <Link2 
                                                onClick={()=>{
                                                    setLastVideo_ID(item.id)
                                                }}
                                                className='more-btn'
                                                to="video" 
                                                delay={500}
                                                spy={true} 
                                                smooth={true}
                                            >
                                                więcej
                                            </Link2>
                                        </li>
                                    ))}
                                </div>
                            </div>
                            <div>
                                <Pagination
                                    classes={{ ul: classes.ul }}
                                    count={count}
                                    size="large"
                                    page={page}
                                    variant="outlined"
                                    shape="rounded"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </Parallax>
                </ParallaxProvider>

            </div>
        </div>
        </>
    );
}

export default LastVideo;