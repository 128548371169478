import React, {useState, useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import '../styles/App.css'
import Poziom_trudnosci from '../functions/PoziomTrudnosci.js';
import ReactPlayer from 'react-player'
import parse from 'html-react-parser';
import { Rating } from 'react-simple-star-rating'
import { ReactComponent as FullStar } from "../images/fullStar.svg"
import { ReactComponent as EmptyStar } from "../images/emptyStar.svg"
import cebula from "../images/cebula.png"
import product_img_4 from "../images/Product_img_4.png"
import product_img_3 from "../images/product_img_3.png"
import product_img_2 from "../images/product_2_img.png"
import pomidor from "../images/pomidor.png"
import time_icon from "../images/time.svg"
import { Stack, Button, Snackbar } from '@mui/material';
import ok from "../images/ok.png"
import err from "../images/err.png"

const Product = (props) => {
  const [playing, setPlaying] = useState(false)
  const [played, setPlayed] = useState(0);

  const [rateUpdate, setRateUpdate] = useState();
  const [newData, setNewData] = useState()

  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    fetch: ''
  });

  const { vertical, horizontal, open } = state;

  const handleClick = (newState) => () => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };


  const dataGet = () => {
    fetch('https://www.naturalneporanki.pl/v2/bcknd/data.php')
      .then((response) => response.json())
      .then(data => {
        setNewData(data)
        localStorage.setItem('mustReloadData', 'true')
      });
  }

  useEffect(() => {
      dataGet()
  }, [rateUpdate === undefined]);

  let przepis = props.dane.recipes !== undefined && props.dane.recipes.find(element => element.url === props.date.routes.match.params.id);
  
  const updateData = newData && newData.recipes.filter(function(number) {
    return number.id === przepis.id;
  })

  if(updateData){
    przepis = updateData[0];
  }

  let sklad = []


  let ingredientsLength = props.dane.recipes !== undefined ? przepis.ingredients.length : 0


  if(props.dane.recipes !== undefined && ingredientsLength > 0){
    for(let i=0; i < ingredientsLength ; i++){
      sklad.push(<h3>{przepis.ingredients[i].title}</h3>)
      sklad.push(przepis.ingredients[i].list.map((m, index) => { 
        return (
          <>
            <li key={index}>{m}</li>
          </>
        )
      }))
    }
  }


  function Skladniki() {
    const itemList = przepis.ingredients.map((m, index) => { 
      let temp = []
      for(let i = 0; i < m.list.length; i++){
        temp.push(<li>{m.list[i]}</li>)
      }
      return (
        <>
          <h3>{m.title}</h3>
          <ul key={index}>{temp}</ul>
        </>
      )
    })
    return itemList;
  }

  function Kroki() {
    const itemList = przepis.preps.map((m, index) => { 
      let temp = []
      for(let i = 0; i < m.steps.length; i++){
        temp.push(<li>{m.steps[i]}</li>)
      }
      return (
        <>
          <h3>{m.title}</h3>
          <ol key={index}>{temp}</ol>
        </>
      )
    })
    return itemList;
  }



  const tooltipArray = ["Słabe", "Średnie", "Dobre", "Smaczne", "Bardzo smaczne"];  


  const [rating, setRating] = useState(props.dane.recipes !== undefined && (przepis.raiting/5)*100) // initial rating value
  
  const handleRating = (rate) => {
    setRating(rate)
    setRatingFetch(rate)
  }

  const setRatingFetch = async (rating) => {
    await fetch('https://www.naturalneporanki.pl/v2/bcknd/vote.php',{
      method: "POST",
      body: JSON.stringify({
        recipe_id : przepis.id, mark: ((rating * 5) / 100) 
      })
    }).then((response) => response.json())
    .then(data => {
      if(data.status === "ok"){
        let tempRating = data.recipes.filter((item)  => {
          return item.id === przepis.id;
        })
        setRateUpdate(tempRating[0].raiting_num)
        localStorage.setItem('mustReloadData', true);
        setState({ ...state, open: true, fetch: "ok" });
      }else{
        setState({ ...state, open: true, fetch: "err" });
      }
    })
  }


  return (
    <div id={'IDproduct'} className="product">

      <img className='product_img_5' src={cebula} alt={'design'}/>
      <img className='product_img_1' src={pomidor} alt={'design'}/>
      <img className='product_img_2' src={product_img_2} alt={'design'}/>

      <div className='content'>

        <h1 >{props.dane.recipes !== undefined && parse(przepis.title)}</h1>

        <div className='product-additionals'>
          <div className='rating-count'>
            <Rating 
                ratingValue={rating} 
                size={100} 
                fullIcon={<FullStar size={100} />}
                emptyIcon={<EmptyStar size={100} />}
                transition
                disabled
                // style={rateUpdate === undefined ? {filter: 'grayscale(0%)', opacity: '1'} : {filter: 'grayscale(100%)', opacity: '.4'}}
                showTooltip
                readonly={rateUpdate === undefined ? false : true}
                tooltipDefaultText={"Wystaw swoją ocenę"}
                tooltipArray={tooltipArray}
                // onClick={handleRating}
                onClick={rateUpdate === undefined && handleRating}
                allowHover={true}
            />
            {`
              (${przepis.raiting_num} OCEN) 
            `}
          </div>
          <div className='aditional-parm'>
              <div className='r-time'><img src={time_icon} alt="timeicon"/><b>{przepis.time !== null ? przepis.time : "BRAK"}</b> {przepis.time !== null && "MIN"}</div>
              <div className='r-level'>Poziom trudności: <b>{`${props.dane.recipes !== undefined && Poziom_trudnosci(przepis.level)}`}</b></div>
          </div>
        </div>
        <div className={`product-header ${przepis.video !== null ? 'flex-2-product-header' : 'flex-1-product-header'}`}>
          <div style={{backgroundImage: `url(${przepis.photo_url !== null ? przepis.photo_url : '' })`, backgroundSize: `cover`, backgroundPosition: `center`, width: '575px', height: '323px'}}></div>
        
          <div className='video-player-box'>
            <ReactPlayer 
              className={`react-player`}
              pip={true}
              url={przepis.video}
              controls={true}
              playing={playing}
              progressInterval={200}
              onProgress={(progress) => {
                setPlayed(progress.playedSeconds);
              }}

              width={574}
              height={323}
                           
            />
            
          </div>
        </div>

        <div className='flex-2-product-header'>
          <div className='skladniki'>
            {Skladniki()}
          </div>
          <div className='przygotowanie'>
            {Kroki()}
          </div>
        </div>
        <div className='rate'>
          <h3>Zostaw ocenę</h3>
          <div className='rate-stars'>
              <Rating 
                ratingValue={rating} 
                size={50} 
                fullIcon={<FullStar size={50} />}
                emptyIcon={<EmptyStar size={50} />}
                // style={rateUpdate === undefined ? {filter: 'grayscale(0%)', opacity: '1'} : {filter: 'grayscale(100%)', opacity: '.4'}}
                showTooltip
                readonly={rateUpdate === undefined ? false : true}
                tooltipDefaultText={"Wystaw swoją ocenę"}
                tooltipArray={tooltipArray}
                // onClick={handleRating}
                onClick={rateUpdate === undefined && handleRating}
                allowHover={true}
              />
          </div>
            {`
              (${props.dane.recipes !== undefined && przepis.raiting_num} OCEN) 
            `}
          <br/>
          <Link className='alllistBTN' to={`/lista-przepisow`}>Zobacz wszystkie przepisy</Link>
        </div>

      </div>

      <img className='product_img_3' src={product_img_3} alt={'design'}/>
      <img className='product_img_4' src={product_img_4} alt={'design'}/>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        ContentProps={{
          sx: {
            background: "#b5db78",
            color: '#1c5b28',
            textAlign: "Center",
            width: 400
          }
        }}
      >
        <div className={`SnackBar ${state.fetch === 'ok'? 'ok' : 'err'}`}>
          <img src={state.fetch === 'ok' ? ok : err} alt={'ok'}/>
          <span>{state.fetch === 'ok' ? 'Twoja ocena zostało dodana!' : 'Twoja ocena nie została dodana prawidłowo'}</span>
        </div>
      </Snackbar>
      

    </div>
  );
}

export default Product;