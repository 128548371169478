import React from 'react';
import { Route } from 'react-router-dom';
const Footer = () => {

  let currentTime = new Date()
  let year = currentTime.getFullYear()

  return (
    <div>
      ©Tarczyński {year} - <button onClick={()=> window.open("https://naturalneporanki.pl/img/polityka-prywatnosci.pdf", "_blank")}>Polityka prywatności</button>
    </div>
  );
}

export default Footer;
