import React, {useState} from 'react';
import '../styles/App.css'
import Baner from '../components/Baner'
import Promo from '../components/Promo'
import LastVideo from '../components/LastVideo'
import SearchRecipe from '../components/SearchRecipe'
import RankingRecipe from '../components/RankingRecipe'


const HomePage = (props) => {

    const [video_id, setVideo_id] = useState()
    // console.log('HomePage_video_id',video_id)
    // console.log("HomePage props.data", props)
    // console.log("HomePage props.data", props.data.recipes)

    // const [data, setData] = useState()

    // const dataGet = () => {
    //     fetch('https://www.naturalneporanki.pl/v2/bcknd/data.php')
    //     .then((response) => response.json())
    //     .then(data => {
    //         setData(data)
    //     });
    // }

    // useEffect(() => {
    //     dataGet()
    // }, []);

    return (
        <div className="home">
            <Baner video_id={video_id} setVideo_id={setVideo_id}/>
            <Promo/>
            <LastVideo data={props.data} video_id={video_id}/>
            <SearchRecipe data={props.data.recipes}/>
            <RankingRecipe data={props.data.recipes}/>
        </div>
    );
}

export default HomePage;