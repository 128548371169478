import React, {useState, useEffect} from 'react';
import { Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from "../images/logo.svg"
import { ReactComponent as Search } from "../images/search.svg"
import { ReactComponent as FB } from "../images/facebook.svg"
import { ReactComponent as INS } from "../images/instagram.svg"
import { ReactComponent as YT } from "../images/youtube.svg"
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { Tooltip } from '@mui/material';
import { Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/styles';
import parse from 'html-react-parser';

import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';


const MyAutocomplete = styled(Autocomplete)({
  background: 'rgb(181, 219, 120)',
  fontFamily: 'Montserrat',
  border: 0,
  boxShadow: '0px 23px 32px 0 rgb(28 91 40 / 10%)',
  paddingTop: 0,
  paddingBottom: 0,
  width: '100%',
  maxWidth: 540,
  height: 68,
  borderRadius: 15,
  ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
    height: '42px',
    maxWidth: 'unset',
    borderRadius: '8px',
  }
});

const cssTextField = makeStyles({
  root: {
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '0',
        height: '68px',
        borderRadius: '15px',
        borderColor: 'white',
        top: '0',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'yellow',
      },
    },
  },
});

const useStyles = makeStyles({
  root: {
    '& div.MuiOutlinedInput-root': {
      height: 68,
      borderRadius: 15,
      fontFamily: 'Montserrat',
      paddingLeft: 20,
      paddingRight: 20,
      color: "#1c5b28",
      fontWeight: "bold",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ['@media (max-width:1000px)']: { // eslint-disable-line no-useless-computed-key
        height: '42px',
      }
    },
    '& div.MuiAutocomplete-popper > div': {
      background: 'rgb(181, 219, 120)',
      color: '#1C5B28',
      borderRadius: 15,
      marginTop: '10px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& div.MuiAutocomplete-popper *': {
      color: '#1C5B28',
      fontFamily: 'mochamattariw03-regularRg',
      fontSize: 17,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& div.MuiAutocomplete-popper li:hover': {
      background: '#f47921',
      color: '#fff',
    },
    '& div.MuiAutocomplete-popper li:hover a': {
      // background: '#f47921',
      color: '#fff',
    }
  }
});



const Header = (props) => {

  const classes = useStyles();
  const classes2 = cssTextField();
  const data = props.data || []

  const [selectedTemp, setSelectedTemp] = useState({label: '', id: ''});
  const [selected, setSelected] = useState(selectedTemp);

  let options = []

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 600);
    });
  }, []);

  console.log('scroll',scroll)

  if(data.recipes && data.recipes.length > 0){
    for(let i=0; i < data.recipes.length ; i++){
      const regex = /(<([^>]+)>)/ig;
      const result = data.recipes[i].title.replace(regex, '');

      options.push({label: result, id: data.recipes[i].url})
    }
  }


  const filterOptions = (options, inputValue) => {
    let newOptions = [];

    let temp = inputValue.inputValue || "x"

    newOptions = options.filter((item)  => {
      let all_str = item.label.toLowerCase();
      return all_str.indexOf(temp) > -1; 
    });

    return newOptions;
  };


    return (
      <div className={classes.root}>
        <div className='topHeader'>
          <Link to={`/`} className={`scrolling-logo ${scroll ? "scroll" : ""}`}>
            <Logo style={{width: "100px"}}/>
          </Link>
          <div className='content gridTop'>
            <div className="logo">
              <Link to={`/`}>
                <Logo style={{width: "270px"}}/>
              </Link>
            </div>
            <div className="search">
              <div className="search-box">
                <MyAutocomplete
                  noOptionsText={'Brak wyników...'}
                  filterOptions={filterOptions}
                  forcePopupIcon={true}
                  filterSelectedOptions={true}
                  disablePortal
                  id="combo-box-demo"
                  textInputProps
                  options={options || []}
                  onChange={(event, value) => {
                    setSelected(value || selectedTemp)
                  }}
                  getOptionLabel={option => option.label}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <Link className='more-btn-box' to={`/przepis/${option.id}`}>{parse(option.label)}</Link>
                    </Box>
                  )}
                  renderInput={(params) => 
                    <TextField 
                      className={classes2.root} 
                      {...params} 
                      label=""
                      placeholder={"Wpisz przepis..."} 
                      textInputProps 
                      inputProps={{
                        ...params.inputProps,
                        'aria-label': 'search input',
                        'data-testid': 'autocomplete-input'
                      }}
                    
                    />
                  }

                />

                <span className='fixBTN'></span>
              </div>
            </div>
            <div className="social">
              <div style={{position: "relative"}}>
                <div style={{position: "relative"}}>
                  <button onClick={()=> window.open("https://www.facebook.com/tarczynskipl", "_blank")}>
                    <FB/>
                  </button>
                </div>
                <button onClick={()=> window.open("https://www.instagram.com/tarczynskipl/?hl=pl", "_blank")}>
                    <INS/>
                </button>
                <button onClick={()=> window.open("https://www.youtube.com/channel/UCoVNAxFntAiU2zE_lqCE4kw", "_blank")}>
                    <YT/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Header;