import React, {useEffect, useState} from 'react';
import Link2 from 'react-scroll/modules/components/Link';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import przepis_1_hd from '../images/Grudzien22-Lomnica_szynka_1920x903.jpg'
import przepis_1_m from '../images/Grudzien22-Lomnica_szynka_1172x903.jpg'


// import przepis_2_hd from '../images/STADION_meksyk_1920x903.jpg'
// import przepis_2_m from '../images/STADION_meksyk_1172x903.jpg'

// import przepis_2_hd from '../images/STADION_arabia_1920x903.jpg'
// import przepis_2_m from '../images/STADION_arabia_1172x903.jpg'

import przepis_2_hd from '../images/Grudzien22-Lomnica_kurczak_1920x903.jpg'
import przepis_2_m from '../images/Grudzien22-Lomnica_kurczak_1172x903.jpg'

// import baner_5 from '../images/Event_STADION_meksyk_1920x903.jpg'
// import baner_5_m from '../images/Event_STADION_meksyk_1172x903.jpg'

// import baner_5 from '../images/Event_STADION_arabia_1920x903.jpg'
// import baner_5_m from '../images/Event_STADION_arabia_1172x903.jpg'

import baner_5 from '../images/Grudzien22-Lomnica_EVENT_1920x903.jpg'
import baner_5_m from '../images/Grudzien22-Lomnica_EVENT_1172x903.jpg'


import { ReactComponent as Dot } from "../images/slider.svg"
import { ReactComponent as Dot2 } from "../images/slider2.svg"
import Link from 'react-router-dom/Link';


const Baner = (props) => {

  const [width, setWidth] = useState(window.innerWidth);
  const [dinamicID, setDinamicID] = useState(25);

  let url = window.location.hash

  const eventsTab = [
    {id: 25, name: '#lomnica'},
    {id: 24, name: '#katar-arabia'},
    {id: 23, name: '#katar-argentyna'},
    {id: 22, name: '#katar-meksyk'},
    {id: 21, name: '#rudawach-janowickich'},
    {id: 20, name: '#dolina-bobru'},
    {id: 19, name: '#zagorze'},
    {id: 18, name: '#runmageddon'},
    {id: 13, name: '#panthers'},
    {id: 12, name: '#bardo'},
    {id: 11, name: '#chojnik'},
    {id: 10, name: '#zloty-stok'},
    {id: 9, name: '#twierdza-srebrna-gora'},
    {id: 8, name: '#polana-jakuszycka'},
    {id: 7, name: '#winnice-silesian'},
    {id: 6, name: '#sad-szwedo'},
    {id: 5, name: '#zamek-grodziec'},
    {id: 4, name: '#dworek-nad-wkra'},
  ]

  let nametodisplay = eventsTab.filter((o)=>{
    return o.name === url
  })

  useEffect(() => {
    if(nametodisplay.length > 0){
      setDinamicID(nametodisplay[0].id)
      props.setVideo_id(nametodisplay[0].id)
    }
  }, [nametodisplay.length > 0]);

  const updateDimensions = () => {
      setWidth(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    if(url !== ""){
      if(window.location.href === `http://localhost:3000/${url}` || window.location.href === `https://naturalneporanki.pl/${url}`){
        setTimeout(()=>{
          window.scrollTo({
            top: width > 1000 ? 1700 : 1000,
            behavior: 'smooth',
          });
        }, 650)
      }
    }
  }, []);


  return (
    <>
      <div className='baner'>
        <div className='baner-bootom-mask'></div>
        <Carousel 
          showThumbs={false}
          showArrows={false}
          centerMode={false}
          emulateTouch={true}
          autoPlay={true}
          interval={10000}
          infiniteLoop={true}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            const defStyle = { marginLeft: 14, color: "white", cursor: "pointer" };
            const style = isSelected
              ? { ...defStyle, opacity: "1" }
              : { ...defStyle, opacity: ".6" };
            return (
              <span
                style={style}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                aria-label={`${label} ${index + 1}`}
              >
                {<Dot2/>}
              </span>
            );
          }}
          >
          <div>
            <img src={width < 650 ? baner_5_m : baner_5} alt={''}/>
            <div className='baner-info'>
              <div className='baner-hero'>
                <ParallaxProvider>
                  <Parallax speed={2}>
                    <span className='baner-hero-row-1'>Wielka niespodzianka w Łomnicy</span>
                    <span className='baner-hero-row-2'>wyjątkowe śniadanie w Pałacu</span>
                  </Parallax>
                </ParallaxProvider>
              </div>
              <ParallaxProvider>
                  <Parallax speed={1}>
                    <Link2
                      onClick={() => props.setVideo_id(dinamicID)}
                      className='more-btn-baner'
                      to="video" 
                      // to='IDprzepis'
                      delay={300}
                      spy={true} 
                      smooth={true}
                    >
                      Zobacz film
                    </Link2>
                    {/* <Button onClick={handleClick5}></Button> */}
                  </Parallax>
                </ParallaxProvider>
            </div>
          </div>

          <div>
            <img src={width < 650 ? przepis_2_m : przepis_2_hd} alt={''}/>
            <div className='baner-info'>
              <div className='baner-hero'>
                <ParallaxProvider>
                  <Parallax speed={2}>
                    <span className='baner-hero-row-1'>Tost „Karkonoskie Kyselo”</span>
                    <span className='baner-hero-row-2'>z parówkami 100% z kurczaka</span>
                  </Parallax>
                </ParallaxProvider>
              </div>
              <ParallaxProvider>
                  <Parallax speed={1}>
                    <Link className='more-btn-baner' to={`przepis/Tost-Karkonoskie-Kyselo-parowkami-100-kurczaka`}>
                      więcej
                    </Link>
                  </Parallax>
                </ParallaxProvider>
            </div>
          </div>

          <div>
            <img src={width < 650 ? przepis_1_m : przepis_1_hd} alt={''}/>
            <div className='baner-info'>
              <div className='baner-hero'>
                <ParallaxProvider>
                  <Parallax speed={2}>
                    <span className='baner-hero-row-1'>Chlebunik z kozim jogurtem</span>
                    <span className='baner-hero-row-2'>i parówkami 100% z szynki</span>
                  </Parallax>
                </ParallaxProvider>
              </div>
              <ParallaxProvider>
                  <Parallax speed={1}>
                  <Link className='more-btn-baner' to={`/przepis/chlebunik-kozim-jogurtem-parowkami-100-szynki`}>
                      więcej
                    </Link>
                  </Parallax>
                </ParallaxProvider>
            </div>
          </div>



        </Carousel>
      </div>
      
      
      {/* <div className='baner'>
        <div className='baner-info'>
          <div className='baner-hero'>
            <ParallaxProvider>
              <Parallax speed={4}>
                <span className='baner-hero-row-1'>Poszukiwanie złotego śniadania</span>
                <span className='baner-hero-row-2'>w kopalni złoty stok</span>
              </Parallax>
            </ParallaxProvider>
          </div>
          <ParallaxProvider>
              <Parallax speed={2}>
                <Link2 
                  className='more-btn-baner'
                  to="video" 
                  // to='IDprzepis'
                  delay={300}
                  spy={true} 
                  smooth={true}
                >
                  Zobacz film
                </Link2>
              </Parallax>
            </ParallaxProvider>
        </div>
        <div className='baner-bootom-mask'></div>
      </div> */}
    </>
  );
}

export default Baner;