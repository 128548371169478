import React from 'react';
// import { ReactComponent as Img1 } from "../images/przepisy.svg"
// import { ReactComponent as Img2 } from "../images/warzywa.svg"
// import { ReactComponent as Img3 } from "../images/miejsce.svg"

import bazylia from '../images/bazylia2.png'
import przyprawy from '../images/przyprawy2.png'
import przepisy from "../images/przepisy.svg"
import warzywa from "../images/warzywa.svg"
import miejsce from "../images/inspiracje.svg"
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';

const Promo = () => {
  return (
    <div className='informations'>

        <ParallaxProvider>
            <Parallax speed={1}>
                <img src={bazylia} className='img-bazylia2' alt='bazylia'/>
            </Parallax>
        </ParallaxProvider>
        <ParallaxProvider>
            <Parallax speed={2}>
                <img src={przyprawy} className='img-przyprawy2' alt='bazylia'/>
            </Parallax>
        </ParallaxProvider>
        
        <div className='content'>
            <div className='information-title'>
                <span>NATURALNE PORANKI</span> TO WYJĄTKOWY CYKL KULINARNYCH PODRÓŻY PO POLSCE. W PRAWDZIWIE INSPIRUJĄCYCH 
                MIEJSCACH SZUKAMY LOKALNYCH SKŁADNIKÓW I TWORZYMY Z NICH NATURALNE ŚNIADANIA
                NA DOBRY POCZĄTEK KAŻDEGO DNIA.
            </div>
            <ParallaxProvider>
                <Parallax speed={0}>
                    <div className='information-bullets'>
                        <div className='information-bullets_bullet'>
                            <div className='bullet-img'>
                                <img src={warzywa}  alt='bazylia'/>
                            </div>
                            <div className='bullet-title'>
                                Lokalne składniki
                            </div>
                            <div className='bullet-info'>
                                Odkrywamy skarby regionalnej kuchni.
                            </div>
                        </div>
                        <div className='information-bullets_bullet'>
                            <div className='bullet-img'>
                                <img src={miejsce}  alt='bazylia'/>
                            </div>
                            <div className='bullet-title'>
                                Inspirujące miejsca
                            </div>
                            <div className='bullet-info'>
                                Gotujemy w otoczeniu najciekawszych atrakcji w Polsce.
                            </div>
                        </div>
                        <div className='information-bullets_bullet'>
                            <div className='bullet-img'>
                                <img src={przepisy}  alt='bazylia'/>
                            </div>
                            <div className='bullet-title'>
                                Przepisy na naturalne śniadania
                            </div>
                            <div className='bullet-info'>
                                Jemy mądrze, smacznie i pożywnie.
                            </div>
                        </div>
                    </div>
                </Parallax>
            </ParallaxProvider>
        </div>
  </div>  
  );
}

export default Promo;